import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {defineAsyncComponent} from 'vue';
import {createPinia} from 'pinia';

const PiniaStore = createPinia();

import './styles/index.scss';
import 'virtual:svg-icons-register';

import "@mdi/font/scss/materialdesignicons.scss";
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
})


import {directiveClickOutside, directiveTooltip, twoDecimal} from '@/directives';
import appComponents from './app.components';
import {getRoute, pluginGetRoute} from '@plugins/useRoutes';
import {aliases} from "vuetify/iconsets/fa";
import {mdi} from "vuetify/iconsets/mdi";


const listenersForClickEvent = []
const listenersForMouseoverEvent = []


createInertiaApp({
    resolve: name => {
        const pages =
            import.meta.glob('./components/app/pages/**/*.vue', {eager: true});
        let page = pages[`./components/app/pages/${name}.vue`];
        // page.default.layout = page.default.layout || AppMainLayout;
        return page;

        // return pages[`./components/app/pages/${name}.vue`];
    },
    setup({el, App, props, plugin}) {
        let app = createApp({
                render: () => h(App, props),

                data: () => {
                    return {
                        mouseoverTimeout: null
                    }
                },
                methods: {
                    globalClickListener(e) {
                        listenersForClickEvent.forEach(func => func?.(e))
                    },
                    globalMouseoverListener(e) {
                        clearTimeout(this.mouseoverTimeout)
                        this.mouseoverTimeout = setTimeout(() => {
                            listenersForMouseoverEvent.forEach(func => func?.(e))
                        }, 100);
                    }
                },
                mounted() {
                    document.body.addEventListener('click', (e) => this.globalClickListener(e));
                    document.body.addEventListener('mouseover', (e) => this.globalMouseoverListener(e))
                },

                beforeUnmount() {
                    document.body.removeEventListener('click', (e) => this.globalClickListener);
                    document.body.removeEventListener('mouseover', (e) => this.globalMouseoverListener)
                }

            },
        ).use(plugin).use(PiniaStore).use(pluginGetRoute).use(vuetify)

            .directive(
                'click-outside',
                directiveClickOutside((listener) => listenersForClickEvent.push(listener))
            )
            .directive('tooltip', directiveTooltip({
                clickEventHandle: (listener) => listenersForClickEvent.push(listener),
                mouseoverEventHandle: (listener) => listenersForMouseoverEvent.push(listener)
            }))
            .directive('two-decimal', twoDecimal)

        app.config.globalProperties.getRoute = getRoute

        app.config.globalProperties._uiSettings = {
                'datepicker': {
                    format: 'MM/dd/yy'
                }
        }

        app = appComponents(app, ['layout', 'ui', 'pages']);

        app.mount(el);
    },
});


