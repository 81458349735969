<script setup>
import {ref, computed, watch, onMounted} from "vue";
import axios from "axios";
import {useForm, usePage} from "@inertiajs/vue3";
import Tiptap from "@boxes/client/BoxClientConnectionActions/modals/components/Tiptap.vue";
import {usePageRequestShowStore} from "@store/pages/page-request-edit";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {useBackendMiddlewarePropsService} from "@services";

const isDialogActive = ref(false);
const loading = ref(false);
const search = ref("");
const templates = ref([]);
const emails = ref([]); // Массив для email'ов клиента
const agentEmails = ref([]); // Массив для email'ов агента
const openedPanels = ref([]); // Массив индексов открытых панелей
const allowSendOther = ref(false);

const form = useForm({
    email: '',         // Email клиента
    from_email: '',    // Email агента (отправителя)
    subject: '',       // Тема письма
    email_body: '',    // Тело письма
});

// Получение страницы и данных клиента
const page = usePage();
const store = usePageRequestShowStore();

// Фильтрация шаблонов по строке поиска с учётом категорий
const filteredTemplatesByCategory = computed(() => {
    const filtered = templates.value.filter((template) =>
        template.description.toLowerCase().includes(search.value.toLowerCase())
    );

    // Группируем отфильтрованные шаблоны по категориям
    const grouped = filtered.reduce((groups, template) => {
        const category = template.category || 'Uncategorized'; // Для шаблонов без категории
        if (!groups[category]) {
            groups[category] = [];
        }
        groups[category].push(template);
        return groups;
    }, {});

    // Обновляем массив открытых панелей на основе количества категорий
    openedPanels.value = Object.keys(grouped).map((_, index) => index);

    return grouped;
});

// Выбор шаблона и подстановка в форму
const selectTemplate = (template) => {
    form.email_body = replaceVariables(template.email_body);
    form.subject = replaceVariables(template.subject);
};
// Заменяем переменные в шаблоне письма
const replaceVariables = (template) => {
    const client = page.props.client.data;
    const agent = page.props.agent[0];
    const flight_legs = page.props.currentRequest.data?.flight_legs[0];
    const cur_req = page.props.currentRequest.data;

    const dynamicData = {
        $customerFirstName: client.first_name || "FirstName",
        $departureDate: flight_legs.flight_date_from ?? '',
        $returnDate: flight_legs.flight_date_to ? flight_legs.flight_date_to : '', // Пустая строка, если нет данных
        $departureCity: flight_legs.departure_airport_object.title ?? "Departure City",
        $arrivalCity: flight_legs.arrival_airport_object.title ?? "Return City",
        $numberOfAdults: cur_req.adults ?? 1,
        $numberOfChildren: cur_req.children ?? 0,
        $numberOfInfants: cur_req.infant ?? 0,
        $agentFullName: agent.first_name + ' ' + agent.last_name || "Agent full Name",
        $agentName: agent.first_name + ' ' + agent.last_name || "Agent full Name",
        $agentFirstName: agent.first_name || "Agent first Name",
        $agentLastName: agent.last_name || "Agent last Name",
        $agentEmail: agent.email || "agent@example.com",
        $agentPhone: agent.phone ? agent.phone.number : "+1-555-555-5555",
    };

    // Заменяем только те переменные, которые есть в dynamicData
    return template.replace(/\$(\w+)/g, (match, p1) => {
        if (dynamicData.hasOwnProperty(match)) {
            const cleanMatch = match.replace(/[.,:!?]/g, '');
            const value = dynamicData[cleanMatch];

            // Если значение пустое (например, $returnDate), удаляем переменную из строки
            return value !== undefined && value !== '' ? value : '';
        }
        return match; // Оставляем переменную нетронутой, если её нет в dynamicData
    });
};

watch(() => form.email_body, (newVal) => {
    if (newVal) {
        form.email_body = replaceVariables(newVal);
    }
});
watch(() => form.subject, (newVal) => {
    if (newVal) {
        form.subject = replaceVariables(newVal);
    }
});


const AlertsManager = useAlertsManagerStore();
const backPerms = useBackendMiddlewarePropsService();

// Логика отправки письма
const sendEmail = async () => {
    loading.value = true
    form.post('/follow-up/send', {
        onSuccess: (e) => {
            loading.value = false
            form.reset();
            AlertsManager.add(e.props.flash.success.message ?? 'Email sent successfully', 'success');
        },
        onError: (errors) => {
            loading.value = false
            AlertsManager.add('Failed to send', 'danger');
            console.error('Error sending email', errors);
        }
    });
};

// Загрузка шаблонов с API
const loadTemplates = async () => {
    try {
        loading.value = true;
        const response = await axios.post("https://infohub.vipfares.com/api/email_templates");
        templates.value = response.data;
        loading.value = false;
    } catch (error) {
        console.error("Ошибка при загрузке шаблонов:", error);
        loading.value = false;
    }
};

// Watch для открытия/закрытия диалога
watch(isDialogActive, (newValue) => {
    if (newValue) {
        loadTemplates();
    }
});

// Заполняем массив emails для клиента и агента при монтировании компонента
onMounted(() => {
    if (page.props.client && page.props.client.data && page.props.client.data.email) {
        emails.value = page.props.client.data.email.map(e => ({id: e.id, email: e.email}));
    }

    // Заполняем emails агента
    if (page.props.agent && page.props.agent.length) {
        agentEmails.value = page.props.agent.map(agent => ({
            id: agent.id,
            email: agent.email,
            name: agent.first_name + ' ' + agent.last_name
        }));
    }

    allowSendOther.value = backPerms.backendPermissions.allowSendFollowUpFromOthers ?? false;
    // allowSendOther.value = false;
});
</script>

<template>
    <v-dialog v-model="isDialogActive" max-width="1200">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                color="rgba(75, 85, 99, .8)"
                class="d-flex align-center justify-center"
                style="width: 36px; height: 36px; min-width: 36px; padding: 0;"
            >
                <v-icon icon="mdi-email" color="white" size="20"></v-icon>
            </v-btn>
        </template>

        <template v-slot:default>
            <v-card style="height:900px; overflow: hidden; position: relative; padding: 20px">
                <v-icon
                    icon="mdi-close"
                    @click="isDialogActive = false"
                    color="black"
                    size="24"
                    class="close-icon"
                    style="position: absolute; top: 10px; right: 10px; cursor: pointer;"
                ></v-icon>

                <v-card-title
                    style="font-weight: 700; margin-top: 5px; font-size: 16px; line-height: 22px; color: #4B5563;">
                    Follow-up Templates
                </v-card-title>
                <v-divider></v-divider>

                <!-- Лоадер во время загрузки -->
                <div v-if="loading" class="loader-container">
                    <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
                </div>

                <v-row v-else>
                    <!-- Левая колонка (с поиском и аккордеоном) -->
                    <v-col cols="4">
                        <v-card style="height: 100%">
                            <v-card-text>
                                <v-text-field
                                    density="compact"
                                    style="margin: 13px;"
                                    variant="outlined"
                                    v-model="search"
                                    label="Search templates"
                                    hide-details
                                    clearable
                                >
                                    <template v-slot:append>
                                        <v-icon>mdi-magnify</v-icon>
                                    </template>
                                </v-text-field>

                                <!-- Аккордеон по категориям с открытыми панелями по умолчанию -->
                                <v-expansion-panels v-model="openedPanels" multiple>
                                    <v-expansion-panel
                                        v-for="(templates, category, index) in filteredTemplatesByCategory"
                                        :key="category"
                                    >
                                        <v-expansion-panel-title>
                                            {{ category }}
                                        </v-expansion-panel-title>
                                        <v-expansion-panel-text>
                                            <v-list density="compact" variant="plain">
                                                <v-list-item
                                                    v-for="(template, index) in templates"
                                                    :key="index"
                                                    style="cursor: pointer;"
                                                    @click="selectTemplate(template)"
                                                >
                                                    <v-list-item-title>
                                                        {{ template.short_name }}

                                                    </v-list-item-title>
                                                    <template v-slot:append>
<!--                                                    <v-tooltip :text="'description'">-->
<!--                                                        <template v-slot:activator="{props}">-->
                                                            <v-icon v-tooltip="template.description ?? 'test'" v-bind:props color="primary">mdi-help-circle-outline</v-icon>
<!--                                                        </template>-->
<!--                                                    </v-tooltip>-->
                                                    </template>
                                                </v-list-item>
                                            </v-list>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Вертикальный разделитель -->
                    <v-divider vertical></v-divider>

                    <!-- Правая колонка (с формой) -->
                    <v-col cols="8">
                        <v-card style="height: 100%">
                            <v-card-text>
                                <!-- Новый select для выбора агента (From) -->
                                <v-select
                                    density="compact"
                                    v-if="allowSendOther"
                                    variant="outlined"
                                    label="From"
                                    v-model="form.from_email"
                                    :items="agentEmails"
                                    item-title="email"
                                    item-value="email"
                                    :menu-props="{ maxHeight: '200px' }"
                                ></v-select>

                                <!-- Существующий select для выбора email клиента -->
                                <v-select
                                    variant="outlined"
                                    density="compact"
                                    label="Client email"
                                    v-model="form.email"
                                    :items="emails"
                                    item-title="email"
                                    item-value="id"
                                ></v-select>

                                <!-- Поле для ввода темы письма -->
                                <v-text-field
                                    density="compact"
                                    label="Subject"
                                    variant="outlined"
                                    v-model="form.subject"
                                ></v-text-field>

                                <!-- Компонент Tiptap для ввода тела письма -->
                                <Tiptap v-model="form.email_body"/>
                            </v-card-text>
                            <v-card-text>
                                <p style="padding: 0 0 0 16px; margin: 0"><b>Variables which you can use:</b></p>
                                <div class="two-columns">
                                    <ol>
                                        <li>$customerFirstName</li>
                                        <li>$departureDate</li>
                                        <li>$arrivalDate</li>
                                        <li>$departureCity</li>
                                        <li>$arrivalCity</li>
                                        <li>$numberOfAdults</li>
                                        <li>$numberOfChildren</li>
                                        <li>$numberOfInfants</li>
                                        <li>$agentFullName or $agentName</li>
                                        <li>$agentFirstName</li>
                                        <li>$agentLastName</li>
                                        <li>$agentEmail</li>
                                        <li>$agentPhone</li>
                                    </ol>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card-actions style="margin-top: 10px">
                    <v-spacer/>
                    <v-btn
                        color="white"
                        style="background-color: #4c78dd"
                        :disabled="!form.email_body || !form.email || !form.subject"
                        @click="sendEmail"
                    >
                        Send Email
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<style scoped>
.loader-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.two-columns {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
    font-size: 14px;
}
</style>
